import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Musical / Μουσική Αγωγή - Παιδικό Τμήμα";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Το τραγούδι στα παιδιά είναι ένα σημαντικό μέσο καλλιέργειας της
          φαντασίας και ανάπτυξης της κοινωνικότητας.
        </P>
        <P>
          Μέσα από ατομικές και ομαδικές δραστηριότητες βασισμένες στο
          ρεπερτόριο του Μιούζικαλ τα παιδιά του τμήματος Musical Kids θα
          αναπτύξουν μουσικές και φωνητικές δεξιότητες, θα εξασκηθούν στην
          ομαδική δουλειά, θα ανακαλύψουν κλίσεις και καινούριες ιδέες και θα
          περάσουν όμορφα.
        </P>
        <P>
          Το τμήμα αφορά κάθε παιδί που αγαπάει το τραγούδι, τη μουσική και τη
          δημιουργικότητα.
        </P>
      </Section.Main>
    </Layout>
  );
}
